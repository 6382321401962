import { styled } from "../stitches.config";

export const Stack = styled("div", {
  display: "grid",
  gridAutoFlow: "row",
  gridGap: "$2",

  variants: {
    gap: {
      small: {
        gridGap: "$1",
      },
      large: {
        gridGap: "$4",
      },
    },
    direction: {
      horizontal: {
        gridAutoFlow: "column",
      },
    },
  },
});
