import React from "react";
import { styled, css } from "../stitches.config";
import Icon from "./Icon";

export function Button({
  children,
  icon,
  iconSide = "left",
  ...props
}: React.PropsWithChildren<any>) {
  return (
    <StyledButton {...props} data-cursor={"pointer"}>
      <ButtonContents icon={icon} iconSide={iconSide}>
        {children}
      </ButtonContents>
    </StyledButton>
  );
}

export function LinkButton({
  children,
  icon,
  iconSide = "left",
  ...props
}: React.PropsWithChildren<any>) {
  return (
    <StyledLinkButton {...props}>
      <ButtonContents icon={icon} iconSide={iconSide}>
        {children}
      </ButtonContents>
    </StyledLinkButton>
  );
}
// This is needed for some Radix components like Menu that need
// a ref to function
export const ButtonForwardRef = React.forwardRef<HTMLButtonElement, any>(
  function Button(
    {
      children,
      icon,
      iconSide = "left",
      ...props
    }: React.PropsWithChildren<any>,
    ref
  ) {
    return (
      <StyledButton {...props} ref={ref}>
        <ButtonContents icon={icon} iconSide={iconSide}>
          {children}
        </ButtonContents>
      </StyledButton>
    );
  }
);

function ButtonContents({
  icon,
  iconSide,
  children,
}: React.PropsWithChildren<any>) {
  return (
    <ButtonContent>
      {icon && iconSide === "left" && <Icon icon={icon} />}
      {children}
      {icon && iconSide === "right" && <Icon icon={icon} />}
    </ButtonContent>
  );
}

export const buttonHoverActiveStyles = {
  "&:hover": {
    background: "$transparentBlack05",
  },

  "&:active": {
    background: "$transparentBlack10",
  },

  "&[disabled]": {
    opacity: 0.5,
  },
};

export const buttonStyles = css({
  margin: "$1",
  padding: "$buttonPadding $2",
  background: "transparent",
  color: "$paper10",
  borderRadius: "$2",
  border: "none",
  cursor: "pointer",
  fontSize: "$4",
  display: "inline-flex",
  placeContent: "center",
  fontFamily: "$default",

  ...buttonHoverActiveStyles,
  variants: {
    hover: {
      true: {
        // artificial hover state
        ...buttonHoverActiveStyles["&:hover"],
      },
      false: {},
    },
    nohover: {
      true: {
        "&:hover": {
          background: "transparent",
        },
      },
    },
    color: {
      primary: {
        border: "2px solid white",
        background: "$buttonBlue",
        position: "relative",

        "&:hover": {
          background: "$buttonBlueDarker",
        },

        "&:active": {
          background: "$buttonBlueDarkest",
        },
      },
      secondary: {
        background: "$transparentBlack05",

        "&:hover": {
          background: "$transparentBlack10",
        },

        "&:active": {
          background: "$transparentBlack15",
        },
      },
      brown: {
        background: "$paper10",
        color: "white",
        "&:hover": {
          background: "$paper10",
        },

        "&:active": {
          background: "$paper10",
        },
      },
      destructive: {
        background: "$lightRed",
        color: "white",

        [`& ${Icon}`]: {
          color: "white",
        },

        "&:hover": {
          background: "$darkenedRed1",
        },

        "&:active": {
          background: "$darkenedRed2",
        },
      },
    },
    size: {
      large: {
        padding: "$3",
        borderRadius: "$2",
        display: "block",
        width: "100%",
        marginLeft: "0",
        marginRight: "0",
        marginTop: "0",
      },
    },
    marginOff: {
      true: {
        margin: "0",
      },
    },
  },
});

// https://github.com/modulz/stitches/issues/329
export const StyledButton = styled("button", {
  ...buttonStyles,
  userSelect: "none",
});

export const StyledLinkButton = styled("a", {
  ...buttonStyles,
  textAlign: "center",
  textDecoration: "none",
  userSelect: "none",
});

export const SegmentedButtonWrapper = styled("div", {
  display: "inline-flex",
  Button: {
    position: "relative",

    "&::before": {
      content: `""`,
      display: "block",
      position: "absolute",
      top: "$buttonPadding",
      bottom: "$buttonPadding",
      right: "0",
      background: "$transparentBlack05",
      width: "1px",
    },

    "&:first-child:not(:only-child)": {
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },

    "&:last-child:not(:only-child)": {
      marginLeft: "0",
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0",
      "&::before": {
        display: "none",
      },
    },

    "&:not(:last-child):not(:only-child)": {
      marginRight: "0",
    },
  },
});

// Keep icon and non-icon buttons same height
// Add gaps between children
const ButtonContent = styled("div", {
  display: "inline-grid",
  gridAutoFlow: "column",
  gridGap: "$1",
  placeItems: "center",
  minHeight: "$5",
});

export const InlineButton = styled("button", {
  background: "transparent",
  border: 0,
  padding: 0,
  display: "inline",
  color: "$linkBlue",
  fontSize: "inherit",
  cursor: "pointer",
});

export const UnstyledButton = styled("button", {
  border: "none",
  margin: 0,
  padding: 0,
  width: "auto",
  overflow: "visible",
  background: "transparent",
  color: "inherit",
  font: "inherit",
  lineHeight: "normal",
  textAlign: "inherit",
  cursor: "pointer",
  ["-webkit-font-smoothing"]: "inherit",
  ["-moz-osx-font-smoothing"]: "inherit",
  ["-webkit-appearance"]: "none",
});
