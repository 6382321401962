import { globalCss } from "../stitches.config";

export const globalStyles = globalCss({
  body: {
    overscrollBehaviorX: "none",
    color: "$paper10",
  },
  input: {
    color: "$paper10",
  },
  a: {
    color: "$paper10",
  },
  "[data-radix-dialog]": {
    zIndex: "$dialog",
  },
  "[data-radix-portal]": {
    zIndex: "$dialog !important",
  },
  "main *": {
    cursor: "none !important",
  },
});
