import { createStitches } from "@stitches/react";
import { BLOCK_Z_UPPERBOUND, NAV_BAR_HEIGHT, SIDE_NAV_WIDTH } from "./consts";
export { css } from "@stitches/react";

export const config = createStitches({
  prefix: "",
  theme: {
    colors: {
      hyperlink: "rgb(0,0,255)",
      green: "rgb(101,195,102)",
      transparentBlack: "rgba(0,0,0,.8)",
      transparentWhite: "rgba(255, 255, 255, 0.9)",
      grey30: "rgba(30,30,30)",
      transparentBrown40: "rgba(105,65,65,0.4)",
      brightBeige: "rgba(245, 244, 242, 1)",
      beige: "#FAF8F5",
      menuBeige: "#FFFFFF",
      grey225: "rgba(225,225,225)",
      menuTitleGray: "rgba(85,65,65,0.8)",
      turquoiseLink: "rgba(32,130,130,1)",

      transparentBlack03: "rgba(0, 0, 0, 0.03)",
      transparentBlack05: "rgba(0, 0, 0, 0.05)",
      transparentBlack10: "rgba(0, 0, 0, 0.10)",
      transparentBlack15: "rgba(0, 0, 0, 0.15)",
      transparentBlack25: "rgba(0, 0, 0, 0.25)",
      transparentWhite10: "rgba(255, 255, 255, 0.1)",

      lightRed: "#EC4D3D",
      darkenedRed1: "#C94134",
      darkenedRed2: "#B13A2E",
      inputBackground: "rgba(118,118,128,0.12)",
      buttonBlue: "#A3ECE9",
      buttonBlueDarker: "hsl(178, 66%, 68%)",
      buttonBlueDarkest: "hsl(178, 66%, 60%)",
      linkBlue: "#007AFF",
      toastGray: "#575757",

      primaryTeal: "#88dddd",
      primaryTealHover: "#5ec5c5",
      primaryTealActive: "rgba(62, 175, 175, 1)",
      destructiveRed: "rgba(250, 179, 153, 1)",
      destructiveRedHover: "rgba(238, 143, 109, 1)",
      destructiveRedActive: "rgba(217, 113, 75, 1)",

      labelGrey: "#FAF8F5",

      // user colors
      orangeOld: "rgba(254,215,160,1)",
      pinkOld: "rgba(254,196,210,1)",
      redOld: "rgba(255,183,180,1)",
      yellowOld: "rgba(255,233,164,1)",
      greenOld: "rgba(192,241,200,1)",
      blueOld: "rgba(194,234,253,1)",
      purpleOld: "rgba(218,208,252,1)",

      paper10: "#231A1A",
      paper30: "#503937",
      paper40: "#71514E",
      paper50: "#957873",
      paper60: "#B8A29C",
      paper80: "#EAE1DB",
      paper90: "#F5F1ED",
      paper95: "#FAF8F5",

      paper10_05: "rgba(35, 26, 26, 0.05)",
      paper10_50: "rgba(35, 26, 26, 0.5)",
      paper10_20: "rgba(35, 26, 26, 0.2)",
      paper60_10: "rgba(184, 162, 156, 0.1)",
      paper60_20: "rgba(184, 162, 156, 0.2)",

      pink10: "#3F0019",
      pink50: "#B85875",
      pink60: "#D6718E",
      pink80: "#FFB1C6",
      pink90: "#FFD9E2",
      pink95: "#FFECF0",

      red10: "#410003",
      red50: "#BB5A53",
      red60: "#DB716B",
      red80: "#FFB3AB",
      red90: "#FFDAD5",
      red95: "#FFEDEA",

      coral10: "#390C00",
      coral50: "#B95C37",
      coral60: "#D9754F",
      coral95: "#FFEDE6",
      coral90: "#FFDBCD",
      coral80: "#FFB599",

      orange10: "#2D1600",
      orange50: "#AE640D",
      orange60: "#CD7E28",
      orange95: "#FFEDDF",
      orange90: "#FFDCBD",
      orange80: "#FFB772",

      yellow10: "#221B01",
      yellow50: "#8B7600",
      yellow60: "#A88F12",
      yellow80: "#DECB78",
      yellow90: "#FBEBAD",
      yellow95: "#FDF5D3",

      green10: "#00210A",
      green50: "#3D7F50",
      green60: "#57A26A",
      green80: "#8FDB9E",
      green90: "#BCF5C6",
      green95: "#DEFCE2",

      teal10: "#002021",
      teal50: "#028586",
      teal60: "#00A1A2",
      teal80: "#62D9DA",
      teal90: "#C1F5F6",
      teal95: "#D4FCFC",

      blue10: "#011E2B",
      blue50: "#0080AA",
      blue60: "#199BC9",
      blue80: "#6ED2FF",
      blue90: "#BEE8FF",
      blue95: "#DFF3FF",

      purple10: "#1C1136",
      purple50: "#7C70BB",
      purple60: "#9788D9",
      purple80: "#CCC1FD",
      purple90: "#E7DEFF",
      purple95: "#F5EEFF",
    },
    sizes: {
      1: "0.25rem",
      2: "0.50rem",
      3: "0.75rem",
      4: "1.00rem",
      5: "1.50rem",
      6: "2.00rem",

      navBarHeight: NAV_BAR_HEIGHT + "rem",
      sideNavWidth: SIDE_NAV_WIDTH + "px",
    },
    space: {
      1: "0.25rem",
      2: "0.50rem",
      3: "0.75rem",
      4: "1.00rem",
      5: "1.50rem",
      6: "2.00rem",

      buttonPadding: "0.375rem",
      navBarHeight: "3.00rem",
    },
    radii: {
      1: "0.25rem",
      2: "0.50rem",
      3: "0.75rem",
      4: "1.00rem",
      circle: "99rem",
    },
    fontSizes: {
      1: ".25rem",
      2: ".5rem",
      3: ".8rem",
      4: "1rem",
      5: "1.125rem",
      6: "1.5rem",
      7: "2.0rem",
    },
    lineHeights: {
      4: "1rem",
      5: "1.5rem",
    },
    fontWeights: {
      medium: "500",
    },
    fonts: {
      default:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
    zIndices: {
      blockOverlay: "30",
      blockMenu: BLOCK_Z_UPPERBOUND.toString(),
      blockPreview: (BLOCK_Z_UPPERBOUND + 1).toString(),
      contextMenu: (BLOCK_Z_UPPERBOUND + 1).toString(),
      drawCanvas: (BLOCK_Z_UPPERBOUND + 4).toString(),
      drawStatus: (BLOCK_Z_UPPERBOUND + 5).toString(),
      chat: (BLOCK_Z_UPPERBOUND + 5).toString(),
      feedback: (BLOCK_Z_UPPERBOUND + 30).toString(),
      cursor: (BLOCK_Z_UPPERBOUND + 40).toString(),
      radixPortal: (BLOCK_Z_UPPERBOUND + 40).toString(),
      sideNav: (BLOCK_Z_UPPERBOUND + 50).toString(),
      dialog: (BLOCK_Z_UPPERBOUND + 60).toString(),
      console: (BLOCK_Z_UPPERBOUND + 60).toString(),
      meter: (BLOCK_Z_UPPERBOUND + 60).toString(),
    },
    shadows: {
      cursor: "0 .1rem .3rem rgba(0,0,0,.25)", // FIXME: stitches doesnt properly support color tokens inside shadow definition
    },
  },
  media: {
    bp1024: "(max-width: 1024px)",
  },
  utils: {
    elevation: (level: 0 | 1 | 2 | 3 | 4 | 5) => {
      switch (level) {
        case 0:
          return {
            boxShadow: "none",
          };
        case 1:
          return {
            boxShadow: "0px 1px 1px rgba(113, 81, 78, 0.1)",
          };
        case 2:
          return {
            boxShadow:
              "0px 0.2px 0.4px rgba(113, 81, 78, 0.04), 0px 0.5px 1px rgba(113, 81, 78, 0.05), 0px 1.2px 2.4px rgba(113, 81, 78, 0.06), 0px 4px 8px rgba(113, 81, 78, 0.1)",
          };
        case 3:
          return {
            boxShadow:
              "0px 0.7px 1.4px rgba(113, 81, 78, 0.06), 0px 2px 4px rgba(113, 81, 78, 0.08), 0px 4.8px 9.6px rgba(113, 81, 78, 0.1), 0px 16px 32px rgba(113, 81, 78, 0.16)",
          };
        case 4:
          return {
            boxShadow:
              "0px 0.7px 1.4px rgba(113, 81, 78, 0.06), 0px 2px 4px rgba(113, 81, 78, 0.08), 0px 4.8px 9.6px rgba(113, 81, 78, 0.1), 0px 16px 32px rgba(113, 81, 78, 0.16)",
          };
        case 5:
          return {
            boxShadow:
              "0px 1.4px 2.9px rgba(113, 81, 78, 0.07), 0px 4px 8px rgba(113, 81, 78, 0.1), 0px 9.6px 19.3px rgba(113, 81, 78, 0.13), 0px 32px 64px rgba(113, 81, 78, 0.2)",
          };
      }
    },
    backgroundBlur: () => () => ({
      backdropFilter: "blur(10px)",
    }),
  },
});

export const { styled, theme, keyframes, globalCss } = config;
