import React, { forwardRef } from "react";
import { styled } from "../stitches.config";
import { ToastContainerProps, ToastProps } from "react-toast-notifications";
import Icon from "../components/Icon";
import { StyledButton } from "./Button";

export const ToastContent = ({
  children,
  onDismiss,
  appearance,
}: Partial<ToastProps>) => (
  <ToastContentWrapper
    role={appearance === "error" ? "alert" : "log"}
    appearance={appearance || "info"}
  >
    <div>{children}</div>
    <IconButton onClick={() => onDismiss && onDismiss()}>
      <Icon icon="Close" />
    </IconButton>
  </ToastContentWrapper>
);

export const Toast = forwardRef<HTMLDivElement, ToastProps>(function Toast(
  { onDismiss, children, appearance },
  ref
) {
  return (
    <ToastWrapper ref={ref}>
      <ToastContent onDismiss={onDismiss} appearance={appearance}>
        {children}
      </ToastContent>
    </ToastWrapper>
  );
});

export const ToastContainer = (props: ToastContainerProps) => (
  <ToastsWrapper>{props.children}</ToastsWrapper>
);

const ToastWrapper = styled("div", {
  paddingTop: "$2",
  cursor: "default",
  pointerEvents: "all",
});

const ToastContentWrapper = styled("div", {
  borderRadius: "$2",
  padding: "$1 $2 $1 $5",
  color: "white",
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  elevation: 2,

  variants: {
    appearance: {
      info: {
        color: "$paper40",
        background: "$paper90",
      },
      error: {
        color: "$coral40",
        background: "$coral90",
      },
      success: {
        color: "$green40",
        background: "$green90",
      },
      warning: {
        color: "$yellow40",
        background: "$yellow90",
      },
    },
  },
});

const IconButton = styled(StyledButton, {
  "&:hover": {
    background: "rgba(255, 255, 255, 0.8)",
  },
});

const ToastsWrapper = styled("div", {
  position: "fixed",
  zIndex: 2147483647, // Display above radix-dialog
  left: "50%",
  transform: "translateX(-50%)",
  top: "$navBarHeight",
});
