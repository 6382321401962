// There is a maximum range for zindex (2147483647)
// so here we compresses timestamps to a smaller range for use as zIndexes.

import { Block } from "../types";

// will run into problems around year 2027
const MakespaceEpochMillis: number = new Date("Jan 1, 2021").getTime();
const STEP_UNIT = 100;

export function timestampToZIndex(updatedAt: number) {
  return Math.round((updatedAt - MakespaceEpochMillis) / STEP_UNIT);
}

let clock = Date.now();

/*
 * nextUpdateAt() generatex the timestamp value
 * to be used as block's z-index value. The returned
 * timestamp value will be greater than value from
 * the previous call.
 */
export function nextUpdatedAt() {
  const now = Date.now();

  if (now > clock + STEP_UNIT) {
    clock = now;
  } else {
    clock += STEP_UNIT;
  }

  return clock;
}

export function nextLowest(blocks: Record<string, Block>) {
  const lowest = Object.values(blocks)
    .filter((b) => b.id !== "placeholder-block" && b.updatedAt > 0)
    .reduce((min, b) => Math.min(min, b.updatedAt), Date.now());

  return lowest - STEP_UNIT;
}
