import { DEFAULT_SPACE_SIZE, DEFAULT_GRID_RESOLUTION } from "../consts";
import { Position, Size } from "../types";

export function pixelToGridPositionSloppy(pixelPosition: Position) {
  return {
    x: pixelPosition.x / DEFAULT_GRID_RESOLUTION,
    y: pixelPosition.y / DEFAULT_GRID_RESOLUTION,
  };
}

export function pixelToGridSizeSloppy(pixelSize: Size) {
  return {
    width: pixelSize.width / DEFAULT_GRID_RESOLUTION,
    height: pixelSize.height / DEFAULT_GRID_RESOLUTION,
  };
}

/*
 * IntFunc is a math func that takes a number and return
 * a integer number. (i.e. Math.round and Math.ceil)
 */
type IntFunc = (x: number) => number;

export function pixelToGridPosition(pixelPosition: Position, func?: IntFunc) {
  if (!func) {
    func = Math.floor;
  }

  return {
    x: pixelPosition.x / DEFAULT_GRID_RESOLUTION,
    y: pixelPosition.y / DEFAULT_GRID_RESOLUTION,
  };
}

export function pixelToGridSize(pixelSize: Size, func?: IntFunc) {
  if (!func) {
    func = Math.floor;
  }

  return {
    width: pixelSize.width / DEFAULT_GRID_RESOLUTION,
    height: pixelSize.height / DEFAULT_GRID_RESOLUTION,
  };
}

export function gridToPixelPosition(gridPosition: Position) {
  return {
    x: gridPosition.x * DEFAULT_GRID_RESOLUTION,
    y: gridPosition.y * DEFAULT_GRID_RESOLUTION,
  };
}

export function snapPixelPosition(pixelPosition: Position) {
  return gridToPixelPosition(pixelToGridPosition(pixelPosition));
}

export function snapPixelSize(size: Size, func?: IntFunc) {
  return gridToPixelSize(pixelToGridSize(size, func));
}

export function gridToPixelSize(gridSize: Size) {
  return {
    width: gridSize.width * DEFAULT_GRID_RESOLUTION,
    height: gridSize.height * DEFAULT_GRID_RESOLUTION,
  };
}

export function subtractPosition(a: Position, b: Position) {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  };
}

export function equalPosition(
  a: Position | undefined,
  b: Position | undefined
) {
  if (!a || !b) return false;
  return a.x === b.x && a.y === b.y;
}

export function addPosition(a: Position, b: Position) {
  return {
    x: a.x + b.x,
    y: a.y + b.y,
  };
}

export function multiplyPosition(a: Position, b: Position) {
  return {
    x: a.x * b.x,
    y: a.y * b.y,
  };
}

export function addSize(a: Size, b: Size) {
  return {
    width: a.width + b.width,
    height: a.height + b.height,
  };
}

export function clampPosition({ x, y }: Position) {
  return {
    x: Math.max(Math.min(x, DEFAULT_SPACE_SIZE.width), 0),
    y: Math.max(Math.min(y, DEFAULT_SPACE_SIZE.height), 0),
  };
}
