import { LiveObject } from "@liveblocks/client";
import { IAgoraRTCClient } from "agora-rtc-sdk-ng";
import { atom, useAtom } from "jotai";
import { atomFamily, atomWithStorage, selectAtom } from "jotai/utils";
import { mapValues } from "lodash";
import { INITIAL_EAR_HEIGHT } from "../av/PositionalAudio";
import { Room } from "../av/RoomService";
import {
  AnyBlock,
  FaceBlock,
  JSONTextBlock,
  LocalConfig,
  Point2D,
  Position,
  Presence,
  Selection,
  SessionId,
  Space,
  User,
  UserLocation,
} from "../types";
import { gridToPixelPosition, gridToPixelSize } from "../utils/gridPosition";
import { timestampToZIndex } from "../utils/updatedAt";

export const currentUserAtom = atom<User | undefined>(undefined);
export const currentSpaceAtom = atom<Space | undefined>(undefined);

// when a user joined a space, a new session is initiated
export const currentSessionIdAtom = atom<SessionId | undefined>(undefined);

export function useSpace() {
  const [currentSpace] = useAtom(currentSpaceAtom);
  return currentSpace;
}
export const onlinesAtom = atom<Set<User["id"]>>(new Set<User["id"]>());

type BlockIdsMap = Record<string, string>; // blockId, blockId because maps are easier to manage than list and order isn't important
export const blockIdsAtom = atom<LiveObject<BlockIdsMap> | undefined>(
  undefined
);

export const faceBlocksAtom = atom<Record<string, FaceBlock>>({});
export const blockYouJustCreatedAtom = atom<string | undefined>(undefined);

export const pastedTextContentAtom = atom<Pick<
  JSONTextBlock,
  "id" | "value"
> | null>(null);

export const updateFaceBlocksAtom = atom(null, (get, set, block: FaceBlock) =>
  set(faceBlocksAtom, { ...get(faceBlocksAtom), [block.id]: { ...block } })
);

export const deleteFromFaceBlocksAtom = atom(null, (get, set, id: string) => {
  const fba = get(faceBlocksAtom);
  if (fba[id]) {
    delete fba[id];
    return set(faceBlocksAtom, { ...fba });
  }
});

export const userLocationsAtom = atom<Record<string, UserLocation>>((get) => {
  const blocks = get(faceBlocksAtom);
  const onlines = get(onlinesAtom);
  const locations: Record<string, UserLocation> = {};
  for (const id in blocks) {
    const block = blocks[id];
    if (block.value && onlines.has(block.value)) {
      locations[block.value] = {
        block: block.id,
        position: gridToPixelPosition(block.position),
        size: gridToPixelSize(block.size),
        locked: block.lockedBy !== undefined && block.lockedBy !== null,
        zIndex: timestampToZIndex(block.updatedAt),
      };
    }
  }
  return locations;
});

export const videoStreamsAtom = atom<Record<SessionId, MediaStreamTrack>>({});
export const audioStreamsAtom = atom<Record<SessionId, MediaStreamTrack>>({});
export const avClientAtom = atom<IAgoraRTCClient | null>(null);

/**
 * a derived atom that returns if the user is currently
 * publishing the video stream to agora channel
 */
export const isPublishingVideoAtom = atom((get) => {
  const sid = get(currentSessionIdAtom);
  const streams = get(videoStreamsAtom);
  return sid && !!streams[sid];
});

// Used to mute audio before a user input has been recorded to satisfy chrome's Audio restriction
export const hasGestureAtom = atom(false);
export const consoleOnAtom = atom(false);

export const spaceMenuOpenAtom = atom<Position | null>(null);

export const bugReportOnAtom = atom(false);
export const pannersAtom = atom<Record<string, PannerNode>>({});
export const earHeightAtom = atom(INITIAL_EAR_HEIGHT);
export const deadZoneAtom = atom(INITIAL_EAR_HEIGHT + 100);
export const rollOffAtom = atom(6);

export const canvasRefAtom = atom<HTMLDivElement | undefined>(undefined);
export function useCanvasRef() {
  const [canvasRef] = useAtom(canvasRefAtom);
  return canvasRef;
}
export const mouseRefAtom = atom<React.MutableRefObject<Point2D> | undefined>(
  undefined
);
export function useMouseRef() {
  const [mouseRef] = useAtom(mouseRefAtom);
  return mouseRef;
}
export const scrollRefAtom = atom<React.MutableRefObject<Point2D> | undefined>(
  undefined
);
export function useScrollRef() {
  const [scrollRef] = useAtom(scrollRefAtom);
  return scrollRef;
}

export const defaultSpringStiffnessAtom = atom(345);
export const defaultSpringDampingAtom = atom(28);
export const defaultSpringMassAtom = atom(1);
export const defaultSpringAtom = atom((get) => {
  return {
    stiffness: get(defaultSpringStiffnessAtom),
    damping: get(defaultSpringDampingAtom),
    mass: get(defaultSpringMassAtom),
  };
});

export const selectionAtom = atom<Selection>({});

export const presenceAtom = atom<Record<User["id"], Presence>>({});

const shallowEqual = (a: any, b: any) => {
  for (const id in a) {
    if (a[id] !== b[id]) return false;
  }
  for (const id in b) {
    if (a[id] !== b[id]) return false;
  }
  return true;
};

export const presenceAtomFamily = atomFamily((key: keyof Presence) => {
  return selectAtom(
    presenceAtom,
    (presences) => mapValues(presences, (p) => p[key]),
    shallowEqual
  );
});

export const audioTrackDeviceAtom = atom<
  MediaDeviceInfo["deviceId"] | undefined
>(undefined);

export const videoTrackDeviceAtom = atom<
  MediaDeviceInfo["deviceId"] | undefined
>(undefined);

export const localConfigAtom = atomWithStorage<LocalConfig>("localConfig", {
  gallery: false,
});

/**
 * When the player is signing up / logging in, the behavior of some parts
 * of the app need to be different. e.g. the cursor. This atom helps
 * notifying the parts of the app that need to change.
 */
export const isInAuthFlowAtom = atom(false);

export const blocksRefAtom = atom<{ current: Record<string, AnyBlock> }>({
  current: {},
});
export const blocksOpsRefAtom = atom<{
  current: {
    upsertBlock?: (b: AnyBlock) => void;
    deleteBlock?: (b: string) => void;
  };
}>({
  current: {},
});

const vw =
  typeof document !== "undefined" && document?.documentElement?.clientWidth;

export const roomAtom = atom<Room | null>(null);

export const sideNavOnAtom = atom(false);

export const scaleAtom = atom(1);

export const scrimShowAtom = atom(false);

// use for your draw mode only
export const drawModeAtom = atom(false);
