export const DEFAULT_SPRING = {
  stiffness: 345,
  damping: 20,
};

export const TOP_SPACER = 160;

export const DEFAULT_SPACE_SIZE = {
  width: 36,
  height: 400,
};

export const DEFAULT_BLOCK_SIZE = {
  width: 10,
  height: 6,
};

// should include padding as well
export const SLIDE_MINIMAP_WIDTH = 120 + 24 + 16;
export const SLIDE_USERLIST_WIDTH = 120 + 24 + 16;

// export const MAX_SCALE = 1.25;
export const MAX_SCALE = 1;

export const DEFAULT_GRID_RESOLUTION = 24;
export const GRID_SEAM = 8;

export const DEFAULT_SPACE_SIZE_PIXELS = {
  width: DEFAULT_SPACE_SIZE.width * DEFAULT_GRID_RESOLUTION,
  height: DEFAULT_SPACE_SIZE.height * DEFAULT_GRID_RESOLUTION,
};

export const DEFAULT_CURSOR_SIZE = 103;
export const DEFAULT_CURSOR_OFFSET = 8;

export const NAV_BAR_HEIGHT = 3;

//       browser maximum z index: 2147483647
export const BLOCK_Z_UPPERBOUND = 2000000000;

export const BOT_NAME = "screenshot_bot";

export enum LIVE_BLOCKS_MAP_KEYS {
  USER = "user-",
  BLOCKIDS = "blockIds",
  SPACE_INFO = "spaceInfo",
  FLAGS = "flags",
}

export enum LIVE_BLOCKS_PRESENCE_KEYS {
  ONLINE = "online",
  POSITION = "position",
  VIEWPORT = "viewport",
  PREVIEW = "preview",
  ISINAUTHFLOW = "isInAuthFlow",
}

export const MOBILE_WIDTH_THRESHOLD = 768;

// How big an image block can appear when pasted or dragged in
export const MAX_IMG_WIDTH = DEFAULT_GRID_RESOLUTION * 32;
export const MAX_IMG_HEIGHT = DEFAULT_GRID_RESOLUTION * 32;

// Images larger than this will be scaled down
export const MAX_IMG_RESOLUTION_WIDTH = 1600;
export const MAX_IMG_RESOLUTION_HEIGHT = 1600;

export const MAX_STREAMING_USERS = 6;

export const GA3_TRACKING_ID = "UA-127609906-1";
export const GA4_TRACKING_ID = "G-K3J4XPC09L";

export const SIDE_NAV_WIDTH = 240;
