import * as RadixDialog from "@radix-ui/react-dialog";
import React from "react";
import { DEFAULT_GRID_RESOLUTION } from "../consts";
import { styled } from "../stitches.config";
import { ButtonForwardRef, buttonStyles } from "./Button";

interface Props {
  title: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  defaultOpen?: boolean;
  label?: string;
  ariaLabel?: string;
  triggerAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

export default function Dialog({
  children,
  open,
  setOpen,
  defaultOpen,
  title,
  label = "Open dialog",
  ariaLabel,
  triggerAs,
}: React.PropsWithChildren<Props>) {
  return (
    <RadixDialog.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={setOpen}
    >
      <RadixDialog.Trigger
        as={triggerAs || ButtonForwardRef}
        aria-label={ariaLabel || label}
      >
        {label}
      </RadixDialog.Trigger>
      <Overlay />
      <Content>
        <Title>{title}</Title>
        {children}
      </Content>
    </RadixDialog.Root>
  );
}

interface DialogNoTriggerProps extends RadixDialog.DialogOwnProps {
  title: string;
}

// For Dialogs meant to be invoked from dropdown Menus
export function DialogNoTrigger({
  title,
  children,
  ...props
}: React.PropsWithChildren<DialogNoTriggerProps>) {
  return (
    <RadixDialog.Root {...props}>
      <Overlay />
      <Content>
        <Title>{title}</Title>
        {children}
      </Content>
    </RadixDialog.Root>
  );
}

interface DialogTakeoverProps {
  title: string;
  message?: string;
  role?: string;
  error?: string;
}

export function DialogTakeover({
  children,
  title,
  message,
  role,
  error,
}: React.PropsWithChildren<DialogTakeoverProps>) {
  return (
    <RadixDialog.Root open={true}>
      <Overlay takeover={true}></Overlay>
      <Content role={role}>
        <Title>{title}</Title>
        <Message>{message}</Message>
        {children}
      </Content>
    </RadixDialog.Root>
  );
}

export const Trigger = styled(RadixDialog.Trigger, {
  ...buttonStyles,
});

const Overlay = styled(RadixDialog.Overlay, {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  variants: {
    takeover: {
      true: {
        backgroundColor: "transparent",
        pointerEvents: "none",
        backgroundImage: `linear-gradient(to right, rgba(50,0,0, 0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(50,0,0, 0.1) 1px, transparent 1px)`,
        backgroundSize: `${DEFAULT_GRID_RESOLUTION}px ${DEFAULT_GRID_RESOLUTION}px`,
      },
      false: {
        backgroundColor: "$transparentBlack10",
      },
    },
  },
});

const Content = styled(RadixDialog.Content, {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "20rem",
  marginTop: "-5vh",
  padding: "$5",
  borderRadius: "$3",
  border: "1px solid white",
  boxShadow: "$cursor",
  background: "$menuBeige",
  maxWidth: "25rem",
  maxHeight: "85vh",
  fontFamily: "$default",
  display: "grid",
  gridGap: "$4",
  cursor: "default",
  backgroundBlur: "",
});

const Title = styled("h2", {
  fontSize: "$4",
  fontWeight: "$medium",
  margin: 0,
});

export const Message = styled("p", {
  marginTop: 0,
  marginBottom: "$2",
  whiteSpace: "pre-wrap",
});

export const Close = ({
  children,
  secondary,
}: React.PropsWithChildren<{ secondary?: boolean }>) => (
  <RadixDialog.Close
    as={ButtonForwardRef}
    size="large"
    color={secondary ? "secondary" : "primary"}
  >
    {children}
  </RadixDialog.Close>
);

export const Actions = styled("div", {
  display: "grid",
  gridAutoFlow: "column",
  direction: "rtl",
  gridGap: "$2",
  marginTop: "$1",
});
