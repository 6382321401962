import { useAtom } from "jotai";
import React, { useState } from "react";
import { Button } from "../components/Button";
import { Actions } from "../components/Dialog";
import { Input, Label, TextArea } from "../components/Form";
import { Stack } from "../components/Stack";
import { bugReportOnAtom, currentUserAtom } from "../jotai/atoms";
import { styled } from "../stitches.config";
import * as Sentry from "@sentry/react";

export default function Bug() {
  const [bugReportOn, setBugReportOn] = useAtom(bugReportOnAtom);
  const [submitted, setSubmitted] = useState(false);
  const [currentUser] = useAtom(currentUserAtom);

  const onSubmit = async (type: ReportType, email: string, details: string) => {
    try {
      const sentryEventId = Sentry.captureMessage("user bug report", {
        // use fingerprint to allow sending of same message string multiple time
        // within the same session
        fingerprint: [`${Date.now()}`],
      });

      const response = await fetch("/api/bug", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: window.location.toString(),
          type,
          email,
          details,
          uid: currentUser?.id || "",
          sentryEventId,
        }),
      });
      const data = await response.json();
      if (!data.ok) {
        throw new Error(`response error:  ${response.statusText}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitted(true);
    }
  };

  function reset() {
    setBugReportOn(false);
    setSubmitted(false);
  }

  if (!bugReportOn) return <></>;

  return (
    <Container>
      <Dialog
        style={{ zIndex: 9999 }}
        role="region"
        aria-live="polite"
        id="bug-confirmation"
      >
        {submitted ? (
          <Stack>
            <FeedbackGif src="/assets/email.gif" alt="a email being sent" />
            <p> Thank you for your help! </p>
            <Button size="large" color="primary" onClick={() => reset()}>
              Done
            </Button>
          </Stack>
        ) : (
          <BugForm onSubmit={onSubmit} onCancel={reset} />
        )}
      </Dialog>
    </Container>
  );
}

type ReportType = "feedback" | "bug";

function BugForm({
  onSubmit,
  onCancel,
}: {
  onSubmit: (type: ReportType, email: string, details: string) => unknown;
  onCancel: () => void;
}) {
  const [email, setEmail] = useState("");
  const [reportType, setReportType] = useState<ReportType>("feedback");
  const [details, setDetails] = useState("");

  function onFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    onSubmit(reportType, email, details);
  }

  // Prevent key events from getting captured by BlockList for select all/delete
  function onKeyDown(e: React.KeyboardEvent) {
    e.stopPropagation();
  }

  function onReportTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (value !== "feedback" && value !== "bug") {
      throw new Error("internal error: invalid report type value");
    }

    setReportType(value);
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <Label htmlFor="details">
        Questions, Compliments, or Concerns?
        <FeedbackGif
          src={"/assets/email.gif"}
          style={{ position: "absolute", right: "1rem", top: 0 }}
          alt="a email being sent"
        />
      </Label>
      <TextArea
        id="details"
        name="details"
        rows={20}
        value={details}
        onKeyDown={onKeyDown}
        onChange={(e) => setDetails(e.target.value)}
      />
      <RadioGroup>
        <RadioItem>
          <input
            type="radio"
            name="report-type"
            id="report-type-feedback"
            value="feedback"
            checked={reportType === "feedback"}
            onChange={onReportTypeChange}
          />
          <label htmlFor="report-type-feedback">Feedback</label>
        </RadioItem>
        <RadioItem>
          <input
            type="radio"
            name="report-type"
            id="report-type-bug"
            value="bug"
            checked={reportType === "bug"}
            onChange={onReportTypeChange}
          />
          <label htmlFor="report-type-bug">Bug report</label>
        </RadioItem>
      </RadioGroup>
      <Label htmlFor="email">Your Email</Label>
      <Input
        id="email"
        type="email"
        placeholder="(optional)"
        name="email"
        value={email}
        onKeyDown={onKeyDown}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Actions>
        <Button
          size="large"
          color="primary"
          type="submit"
          disabled={details.trim().length === 0}
        >
          Submit
        </Button>
        <Button size="large" type="cancel" onClick={onCancel}>
          Cancel
        </Button>
      </Actions>
    </Form>
  );
}
const FeedbackGif = styled("img", {
  margin: "auto",
});

const Form = styled("form", {
  display: "grid",
  gridGap: "$3",
  width: "60ch",
  maxWidth: "100vw",
});

const Container = styled("div", {
  position: "fixed",
  zIndex: "$console",
  bottom: "$2",
  left: "$2",
  elevation: 2,
});

const Dialog = styled("div", {
  padding: "$4",
  borderRadius: "$1",
  background: "white",
  backgroundBlur: "",
  marginBottom: "$2",
  cursor: "default",
});

const RadioGroup = styled("div", {
  display: "flex",
  alignItems: "center",
  margin: "$4 0",
});

const RadioItem = styled("div", {
  marginRight: "$4",
  display: "flex",
  alignItems: "center",
  fontWeight: "$bold",
  input: {
    cursor: "pointer",
    marginRight: "$1",
  },
  label: {
    cursor: "pointer",
  },
});
