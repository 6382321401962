// Instructions to add new icons
// 1. Export from Figma as SVG
// 2. Open SVG, clean it up:
//     Remove any svg <groups>, <clips>, <defs> — only need <path>
//     Replace fills with fill="currentColor" (instead of black/white)
//     Make sure view box is 20x20 or 24x24... some round number
// 3. Add an import {... as IconName }
// 4. Add IconName to const Icons object as a key

import React from "react";
import AlertCircle from "../assets/alert-circle.svg";
import CheckCircle from "../assets/check-circle.svg";
import Logo from "../assets/logo.svg";
import Cross from "../assets/cross.svg";
import DrawBlock from "../assets/draw.svg";
import ArrowRight from "../assets/icons/arrow-right.svg";
import Brush from "../assets/icons/brush.svg";
import CamOff from "../assets/icons/cam-off.svg";
import CamOn from "../assets/icons/cam-on.svg";
import Checkmark from "../assets/icons/checkmark.svg";
import Chevron from "../assets/icons/chevron.svg";
import Close from "../assets/icons/close.svg";
import FaceDock from "../assets/icons/face-dock.svg";
import ImageSearch from "../assets/icons/image-search.svg";
import ImgBlock from "../assets/icons/image.svg";
import Leave from "../assets/icons/leave.svg";
import Link from "../assets/icons/link.svg";
import Lock from "../assets/icons/lock-fill.svg";
import LockOpen from "../assets/icons/lock-open-fill.svg";
import Menu from "../assets/icons/menu.svg";
import MicOff from "../assets/icons/mic-off.svg";
import MicOn from "../assets/icons/mic-on.svg";
import More from "../assets/icons/more.svg";
import Pause from "../assets/icons/pause.svg";
import People from "../assets/icons/people.svg";
import Play from "../assets/icons/play.svg";
import Plus from "../assets/icons/plus.svg";
import Refresh from "../assets/icons/refresh.svg";
import Sidebar from "../assets/icons/sidebar.svg";
import ScreenBlock from "../assets/icons/screenshare.svg";
import Screenshot from "../assets/icons/screenshot.svg";
import Search from "../assets/icons/search.svg";
import Snapshot from "../assets/icons/snapshot.svg";
import TextBlock from "../assets/icons/text.svg";
import Transparent from "../assets/icons/transparent.svg";
import Upload from "../assets/icons/upload.svg";
import Video from "../assets/icons/video.svg";
import VolumeMute from "../assets/icons/volume-mute.svg";
import Volume from "../assets/icons/volume.svg";
import WebBlock from "../assets/icons/web.svg";
import YouTube from "../assets/icons/youtube.svg";
import TwoBars from "../assets/icons/two-bars.svg";
import ArrowRightUp from "../assets/icons/arrow-right-up.svg";
import Sticky from "../assets/icons/sticky.svg";
import Trashcan from "../assets/icons/trashcan.svg";
import Smiley from "../assets/icons/smiley.svg";
import Stickie from "../assets/icons/stickie.svg";
import Crayon from "../assets/icons/crayon.svg";
import StickerFace from "../assets/icons/sticker-face.svg";
import { styled } from "../stitches.config";

interface Props extends React.SVGProps<SVGSVGElement> {
  icon?: IconType;
  color?: string;
  style?: React.CSSProperties;
}

export type IconType = keyof typeof Icons;

const Icons = {
  AlertCircle,
  ArrowRight,
  CamOn,
  CamOff,
  CheckCircle,
  Chevron,
  Checkmark,
  Close,
  Cross,
  FaceDock,
  ImgBlock,
  Leave,
  Link,
  Lock,
  LockOpen,
  Menu,
  MicOn,
  MicOff,
  More,
  People,
  Plus,
  Refresh,
  Search,
  Snapshot,
  TextBlock,
  Upload,
  WebBlock,
  Transparent,
  Sidebar,
  ScreenBlock,
  ArrowRightUp,
  Screenshot,
  YouTube,
  Play,
  Pause,
  Volume,
  VolumeMute,
  Video,
  DrawBlock,
  Smiley,
  ImageSearch,
  Brush,
  Logo,
  TwoBars,
  Sticky,
  Stickie,
  Trashcan,
  Crayon,
  StickerFace,
  CssBlock: () => <>CSS</>,
};

export default function Icon({ icon, color, style, ...props }: Props) {
  if (!icon) return <Empty />;

  const Icon = Icons[icon];
  return (
    <Icon style={{ color: color ? color : undefined, ...style }} {...props} />
  );
}

const Empty = styled("img", {
  width: "$5",
  height: "$5",
});
