import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import "../index.css";
import "../styledMarkdownText.css";
import "focus-visible";
import { Provider as JotaiProvider } from "jotai";
import { ToastProvider } from "react-toast-notifications";
import { Toast, ToastContainer } from "../components/Toast";
import { HelmetProvider } from "react-helmet-async";
import { styled } from "../stitches.config";
import Bug from "../ui/Bug";
import Head from "next/head";
import Script from "next/script";
import PlausibleProvider from "next-plausible";
import { sendPageview } from "../analytics/pageviews";
import { useRouter } from "next/router";
import { GA3_TRACKING_ID, GA4_TRACKING_ID } from "../consts";
import { globalStyles } from "../css/globalStyle";
import { initAmplitude } from "../analytics/amplitude";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import smoothscroll from "smoothscroll-polyfill";

function SproutApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => sendPageview(url);
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  globalStyles();

  useEffect(() => {
    initAmplitude();
    smoothscroll.polyfill();
    TimeAgo.addDefaultLocale(en);
  }, []);

  return (
    <PlausibleProvider domain="sprout.place">
      <JotaiProvider>
        <Head>
          <title>Sprout</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
        </Head>
        <Script
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=G-K3J4XPC09L"
        ></Script>
        <Script id="google-analytics" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA3_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            gtag('config', '${GA4_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
        `}
        </Script>
        <HelmetProvider>
          <ToastProvider
            autoDismiss={true}
            autoDismissTimeout={5000}
            components={{ Toast, ToastContainer }}
          >
            <Backdrop />
            <Component {...pageProps} />
            <Bug />
          </ToastProvider>
        </HelmetProvider>
      </JotaiProvider>
    </PlausibleProvider>
  );
}

export default SproutApp;

const Backdrop = styled("div", {
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: -99999,
  background: "$beige",
});
