import React from "react";
import { styled } from "../stitches.config";
import { Button } from "./Button";
import type * as Stitches from "@stitches/react";

export const Form = styled("form", {
  display: "grid",
  gridGap: "$4",
});

export const Label = styled("label", {
  fontWeight: 600,
});

const textInput: Stitches.CSS = {
  padding: "$4",
  borderRadius: "$2",
  border: "none",
  backgroundColor: "$paper10_05",
  fontSize: "inherit",
  width: "100%",
  color: "$paper10",
};

export const Input = styled("input", {
  padding: "$4",
  borderRadius: "$2",
  backgroundColor: "$paper10_05",
  fontSize: "inherit",
  width: "100%",
  variants: {
    size: {
      small: {
        padding: "$buttonPadding $2",
        border: "1px solid $transparentBlack05",
      },
    },
  },
});

export const TextArea = styled("textarea", {
  ...textInput,
});

export const ErrorMessage = styled("p", {
  display: "block",
  padding: "$4",
  borderRadius: "$2",
  background: "$lightRed",
  color: "white",
});

export const SuccessMessage = styled("p", {
  display: "block",
  padding: "$4",
  borderRadius: "$2",
  background: "$green",
  color: "white",
});

export const Submit = ({ children }: { children: React.ReactNode }) => (
  <Button size="large" color="primary">
    {children}
  </Button>
);
