import { GA3_TRACKING_ID, GA4_TRACKING_ID } from "../consts";

export const sendPageview = (url: string) => {
  window.gtag("get", GA3_TRACKING_ID, "client_id", (client_id) => {
    // GA4 client id
    window.gtag("set", "user_properties", {
      custom_client_id: client_id,
    });
    // GA3 client id
    window.gtag("set", "dimension1", client_id);

    // Send pageview to Google Analytics
    window.gtag("config", GA4_TRACKING_ID, {
      page_path: url,
    });
    window.gtag("config", GA3_TRACKING_ID, {
      page_path: url,
    });
  });

  // Pageviews are automatically sent to Plausible
};
