import amplitude from "amplitude-js";

export const initAmplitude = () => {
  if (!process.env.NEXT_PUBLIC_AMPLITUDE_KEY) return;
  amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY);
};

export const amplitudeSetUser = (userId: string) => {
  const instance = amplitude.getInstance();
  instance.setUserId(userId);
};

export const amplitudeSetUserProperties = (properties: {
  [key: string]: string | number | boolean;
}) => {
  const instance = amplitude.getInstance();
  instance.setUserProperties(properties);
};
