type Participant = any;

declare global {
  interface Window {
    smuggleStreams: Map<string, MediaStreamAudioSourceNode>;
    smuggleContext?: AudioContext;
    ctx?: AudioContext;
  }
}

export function calculatedGainExponential(
  screenDistance: number,
  earDistance: number,
  refDistance: number,
  rolloffFactor: number
) {
  const distance = Math.sqrt(
    screenDistance * screenDistance + earDistance * earDistance
  );
  return Math.pow(
    Math.max(distance, refDistance) / refDistance,
    -rolloffFactor
  );
}
export const INITIAL_EAR_HEIGHT = 1500;

class PositionalAudioNodes {
  streamNode: MediaStreamAudioSourceNode;
  gain: GainNode;
  panner: PannerNode;

  constructor(
    streamNode: MediaStreamAudioSourceNode,
    gain: GainNode,
    panner: PannerNode
  ) {
    this.streamNode = streamNode;
    this.gain = gain;
    this.panner = panner;
  }

  close() {
    this.streamNode.disconnect();
    this.gain.disconnect();
    this.panner.disconnect();
  }
}

class PositionalAudioResources {
  static createPanner(
    audioContext: AudioContext,
    rollOff: number,
    deadZone: number
  ) {
    const panner = audioContext.createPanner();

    // Uses a convolution with measured impulse responses from human subjects
    panner.panningModel = "HRTF";

    // Reference distance for reducing volume as the audio source moves
    // further from the listener. For distances greater than this the volume
    // will be reduced based on rolloffFactor and distanceModel
    panner.refDistance = deadZone;
    panner.rolloffFactor = rollOff;
    panner.maxDistance = 100_000;
    panner.distanceModel = "exponential";
    if (panner.positionZ) {
      panner.positionZ.value = 0;
    }

    return panner;
  }

  static setMuteAudioElement(participant: Participant, muted: boolean) {
    const voxeetAudioElement = document.getElementById(
      `audio-${participant.id}`
    );
    if (voxeetAudioElement) {
      (voxeetAudioElement as HTMLAudioElement)!.muted = muted;
      (voxeetAudioElement as HTMLAudioElement)!.controls = true;
    }
  }

  resources: Map<string, PositionalAudioNodes>;

  constructor() {
    this.resources = new Map();
  }

  async create(
    audioContext: AudioContext,
    mainGain: GainNode,
    participant: Participant,
    rollOff: number,
    deadZone: number
  ) {
    const currentResource = this.resources.get(participant.info.externalId);

    const stream = participant.streams[0];

    const canResuseStream =
      stream?.id && currentResource?.streamNode.mediaStream.id === stream.id;

    if (currentResource && canResuseStream) {
      console.log("Reusing stream");
      return { panner: currentResource!.panner, gain: currentResource!.gain };
    }

    if (currentResource) {
      console.log(
        "stopping stream: " + currentResource?.streamNode.mediaStream.id
      );
      this.closeForParticipantId(participant.info.externalId);
    }

    try {
      // Grab a streamSourceNode from our vendored voxeet backdoor
      const streamSourceNode = window["smuggleStreams"].get(stream.id);
      if (!streamSourceNode) {
        throw new Error("No streamSourceNode from voxeet!");
      }
      const panner = PositionalAudioResources.createPanner(
        audioContext,
        rollOff,
        deadZone
      );
      const gain = audioContext.createGain();

      streamSourceNode.connect(panner).connect(gain).connect(mainGain);

      this.resources.set(
        participant.info.externalId,
        new PositionalAudioNodes(streamSourceNode, gain, panner)
      );

      return { panner, gain };
    } catch (e) {
      console.warn(
        `Creating media stream failed for participant ${participant.id}`,
        e
      );
    }
  }

  close() {
    for (const id of this.resources.keys()) {
      this.closeForParticipantId(id);
    }
  }

  closeForParticipantId(participantId: string) {
    const participantResources = this.resources.get(participantId);
    if (!participantResources) {
      return;
    }
    participantResources.gain.gain.value = 0;
    participantResources.close();
    this.resources.delete(participantId);
  }
}

function removePositionalAudioSource(participant: Participant) {
  positionalAudioResources.closeForParticipantId(participant.info.externalId);
}

const positionalAudioResources = new PositionalAudioResources();

function getAudioContext() {
  const context = window.smuggleContext || window.ctx;
  if (!context) {
    throw new Error("can't find audio context!");
  }
  return context;
}
let _mainGain: GainNode;
function getMainGain() {
  if (getAudioContext() && !_mainGain) {
    _mainGain = getAudioContext().createGain();
    _mainGain.connect(getAudioContext().destination);
  }
  return _mainGain;
}

async function createPositionalAudioSource(
  participant: Participant,
  rollOff: number,
  deadZone: number
): Promise<PannerNode | undefined> {
  const nodes = await positionalAudioResources.create(
    getAudioContext(),
    getMainGain(),
    participant,
    rollOff,
    deadZone
  );

  setAudioListenerLocation(listner_x, listener_y, listener_z);

  return nodes?.panner;
}

let listner_x: number;
let listener_y: number;
let listener_z: number;
function setAudioListenerLocation(x: number, y: number, z: number) {
  return; // this code is dead for right now
  // if (isNaN(x) || isNaN(y) || isNaN(z)) {
  //   return;
  // }

  // // we save these for the event that the initial listener position is set before the
  // // audio context we steal from voxeet is ready.
  // listner_x = x;
  // listener_y = y;
  // listener_z = z;

  // if (!getAudioContext()) {
  //   return;
  // }

  // if (getAudioContext().listener.positionX) {
  //   getAudioContext().listener.positionX.value = x;
  //   getAudioContext().listener.positionY.value = y;
  //   getAudioContext().listener.positionZ.value = -z;
  // } else {
  //   getAudioContext().listener.setPosition(x, y, -z);
  // }
}

function setPannerLocation(panner: PannerNode, x: number, y: number) {
  return; // this code is dead for right now
  // if (!panner || isNaN(x) || isNaN(y)) {
  //   return;
  // }
  // if (panner.positionX) {
  //   panner.positionX.value = x;
  //   panner.positionY.value = y;
  // } else {
  //   panner.setPosition(x, y, 0);
  // }
}

function setPositionalAudioActive(
  active: boolean,
  participants: Record<string, Participant>
) {
  if (getMainGain()) {
    getMainGain().gain.value = active ? 1 : 0;
  }
  Object.values(participants).forEach((p) =>
    PositionalAudioResources.setMuteAudioElement(p, active)
  );
}
export {
  PositionalAudioResources,
  createPositionalAudioSource,
  removePositionalAudioSource,
  setAudioListenerLocation,
  setPannerLocation,
  setPositionalAudioActive,
};
